import {
  Button,
  Group,
  MantineProvider,
  MantineThemeOverride,
  rem,
} from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import React from "react";
// import classes from "./Demo.module.css";
// const theme = createTheme({
//   colors: {
//     // Add your color
//     deepBlue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
//     // or replace default theme color
//     blue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
//   },

//   shadows: {
//     md: "1px 1px 3px rgba(0, 0, 0, .25)",
//     xl: "5px 5px 3px rgba(0, 0, 0, .25)",
//   },
//   defaultGradient: {
//     from: 'orange',
//     to: 'red',
//     deg: 45,
//   },
//   headings: {
//     fontFamily: "Roboto, sans-serif",
//     sizes: {
//       h1: { fontSize: rem(36) },
//     },
//   },
//   // components: {
//   //   Button: Button.extend({
//   //     classNames: classes,
//   //   }),
//   // },
// });
// const theme = createTheme({
//   defaultGradient: {
//     from: 'orange',
//     to: 'red',
//     deg: 45,
//   },
// });

export function ThemeProvider({ children }) {
  // return (
  //   <MantineProvider
  //     theme={{
  //       colors: {
  //         'ocean-blue': ['#7AD1DD', '#5FCCDB', '#44CADC', '#2AC9DE', '#1AC2D9', '#11B7CD', '#09ADC3', '#0E99AC', '#128797', '#147885'],
  //         'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  //       },
  //     }}
  //   >
  //     <Group>
  //       <Button color="ocean-blue">Ocean blue button</Button>
  //       <Button color="bright-pink" variant="filled">
  //         Bright pink button
  //       </Button>
  //     </Group>
  //   </MantineProvider>)
  // return (
  //   <MantineProvider theme={theme}>
  //     <Button variant="gradient">Button with custom default gradient</Button>
  //   </MantineProvider>
  // );
  return (
    <MantineProvider
      // withGlobalStyles
      // withNormalizeCSS
      theme={
        {
          colors: {
            primary: [
              "#FFFAE1",
              "#FFF3CC",
              "#FFE69B",
              "#FFD764",
              "#FFCB38",
              "#FFC41C", // primary generator by: https://mantine.dev/colors-generator/?color=FEBD00
              "#FFC009",
              "#E3A900",
              "#CA9600",
              "#AF8100",
            ],
            deepBlue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
            // or replace default theme color
            blue: ["#E9EDFC", "#C1CCF6", "#99ABF0" /* ... */],
          },
          shadows: {
            md: "1px 1px 3px rgba(0, 0, 0, .25)",
            xl: "5px 5px 3px rgba(0, 0, 0, .25)",
          },
          fontFamily: "Poppins, sans-serif",
          // globalStyles: (theme) => ({
          //   ".mantine-Modal-title": {
          //     fontSize: theme.fontSizes.xl,
          //     fontWeight: 700,
          //     fontFamily: "Mulish, sans-serif",
          //   },
          // }),
          // components: {
          //   Input: {
          //     defaultProps: {
          //       radius: "md",
          //     },
          //   },
          //   Button: {
          //     defaultProps: {
          //       color: "gray.8",
          //       radius: "md",
          //     },
          //   },
          //   ActionIcon: {
          //     defaultProps: {
          //       radius: "md",
          //     },
          //   },
          //   Pagination: {
          //     defaultProps: {
          //       color: "gray.8",
          //     },
          //   },
          //   Checkbox: {
          //     defaultProps: {
          //       color: "gray.8",
          //     },
          //   },
          //   Card: {
          //     defaultProps: {
          //       radius: "md",
          //     },
          //   },
          //   LoadingOverlay: {
          //     defaultProps: {
          //       h: "100vh",
          //       pos: "fixed",
          //       top: 0,
          //       left: 0,
          //       zIndex: 1000,
          //     },
          //   },
          // },
        } as MantineThemeOverride
      }
    >
      <DatesProvider settings={{ locale: "ko" }}>{children}</DatesProvider>
    </MantineProvider>
  );
}
