import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const DemoPage = lazy(() => import("../pages/demo/DemoPage"));
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const CVPage = lazy(() => import("../pages/cv/CVPage"));

export const AUTH_LOGIN_PATH = "/login";
export const SETTLEMENT_INDEX = "/settlement";
export const SETTLEMENT_DETAIL = "/settlement/:sequence";
export function getSellerRouter(queryClient?: any) {
  return createBrowserRouter([
    {
      path: "/",
      // element: (
      //   // <QueryParamProvider adapter={ReactRouter6Adapter}>
      //     <LoginPage />
      //   // </QueryParamProvider>
      // ),
      errorElement: <div></div>,
      children: [
        {
          path: "/demo",
          element: <DemoPage />,
        },
        {
          path: "/",
          element: <CVPage />,
        },
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: AUTH_LOGIN_PATH,
          element: <LoginPage />,
        },
      ],
    },
  ]);
}
