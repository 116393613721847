import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./router/App";
// import App from "./samples/table/Sample";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Mulish","Poppins"],
  },
});
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
