// import React from "react";
// import Navbar from "./components/nav-bar/Navbar.tsx";
import { LoadingOverlay } from "@mantine/core";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "./ThemeProvider";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { getSellerRouter } from "./seller-router";

// export const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       retry: false,
//       refetchInterval: false,
//       keepPreviousData: true,
//     },
//   },
// });
function App() {
  return (
    <ThemeProvider>
      <ModalsProvider>
        <Suspense fallback={<LoadingOverlay visible={true} />}>
          <RouterProvider router={getSellerRouter()} />
        </Suspense>
      </ModalsProvider>
    </ThemeProvider>
  );
}

export default App;
